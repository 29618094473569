export default class Base
{
    /**
     * Parse handlers. These are provided as {success: ..., failure: ...}.
     * If only a single handler was provided, assume it's a 'success' callback.
     */
    parseResponseHandlers(handlers = {}) {
        if (handlers) {
            if (_.isFunction(handlers)) {
                handlers.success = handlers;
                handlers.failure = _.noop;
                handlers.always = _.noop;
            } else {
                handlers.success = handlers.success || _.noop;
                handlers.failure = handlers.failure || _.noop;
                handlers.always = handlers.always || _.noop;
            }
        } else {
            handlers.success = _.noop;
            handlers.failure = _.noop;
            handlers.always = _.noop;
        }
    }

    bindAll() {
        for (let obj = this; obj; obj = Object.getPrototypeOf(obj)) {

            // We're the end of the inheritance chain if we've reached 'Object'.
            if (obj.constructor.name === "Object") {
                return;
            }

            let names = Object.getOwnPropertyNames(obj);

            // Bind each function to the instance.
            for (let i = 0; i < names.length; i++) {
                let name = names[i];

                // No need to bind getters, as attempting to access them would also
                // invoke them which is something we don't want to do here.
                if (Object.getOwnPropertyDescriptor(obj, name).get) {
                    continue;
                }

                // We're using `defineProperty` here so that we don't make all the
                // class methods enumerable when we replace them.
                if (typeof obj[name] === "function" && name !== "constructor") {
                    Object.defineProperty(this, name, {
                        value: this[name].bind(this),
                        enumerable: false,
                        configurable: true,
                        writable: true,
                    });
                }
            }
        }
    }
}
